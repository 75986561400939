// getConfigQueryString returns a query string of config params based on the next/router.query
// parameter.
export const getConfigQueryString = (
  query: { [key: string]: string | string[] },
  language: string
): string => {
  const params = getConfigQueryParams(query, language);

  return Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
};

// getConfigQueryParams returns a query object based on the next/router.query parameter.
export const getConfigQueryParams = (
  query: {
    [key: string]: string | string[];
  },
  language: string
): { [key: string]: string } => {
  const params: { [key: string]: string } = {};
  Object.keys(query).forEach((key) => {
    if (['primary', 'secondary', 'bg', 'sh', 'thm', 'hbg', 'fbg', 'cbg', 'defCol'].includes(key)) {
      params[key] = query[key] as string;
    } else if (key.startsWith('utm')) {
      params[key] = query[key] as string;
    }
  });
  params['lng'] = language;

  return params;
};
